@keyframes slideToRight {
  from {
    transform: translateX(-100px);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideToLeft {
  from {
    transform: translateX(100px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideToTop {
  from {
    transform: translateY(100px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

// @keyframes continousRight {
//   0% {
//     -webkit-transform: translateX(0);
//   }
//   100% {
//     -webkit-transform: translateX(-100%);
//   }
// }

// @keyframes continousLeft {
//   0% {
//     -webkit-transform: translateX(0);
//   }
//   100% {
//     -webkit-transform: translateX(100%);
//   }
// }

@keyframes continousUp {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-100%);
  }
}
