.downupPopup {
  .downupPopup-header {
    padding: toRem(20);
    color: $color-black;
    border-bottom: solid 1px $color-light-grey;

    .downupPopup-kapat {
      display: none;
    }
  }

  .downupPopup-content {
    color: $color-black !important;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
