* {
  box-sizing: border-box;
}
html {
  overflow-x: hidden;
}

.unhide-x {
  overflow-x: unset;
}

body {
  padding: 0;
  margin: 0 auto;
  color: $color-grey;
  font-family: 'DMSans Regular';
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
p {
  margin: 0;
}

h1 {
  font-size: toRem(60);
  font-family: 'DMSans Bold';
  letter-spacing: -0.03em;
  line-height: 1.1;

  @include media-breakpoint-down(sm) {
    font-size: toRem(40);
    line-height: 1.1;
  }
}

h2 {
  font-size: toRem(48);
  font-family: 'DMSans Bold';
  letter-spacing: -0.03em;
  line-height: 1.05;

  @include media-breakpoint-down(sm) {
    font-size: toRem(32);
    line-height: 1;
  }
}

h3 {
  font-size: toRem(32);
  font-family: 'DMSans Bold';
  letter-spacing: -0.03em;
  line-height: 1.1;

  @include media-breakpoint-down(sm) {
    font-size: toRem(24);
    line-height: 1.1;
  }
}

h4 {
  font-size: toRem(24);
  font-family: 'DMSans Bold';
  letter-spacing: -0.03em;
  line-height: 1.05;

  @include media-breakpoint-down(sm) {
    font-size: toRem(20);
    line-height: 1.1;
  }
}

a {
  text-decoration: none;
}

// For Pattern and Ornament on All Pages
.pattern,
.ornament {
  pointer-events: none;
}
