// Using <a> tag on html
.btn {
  border-radius: 10px;
  text-decoration: none;
  border: none;
  transition: 0.2s;
  font-family: 'DMSans Bold';
  text-align: center;
  text-transform: capitalize;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;

  // Use this property for styling icon inside button
  &__icon {
    width: 20px;
    height: 20px;
    background-image: url('../../media/images/icons/arrow-right.svg');
  }

  &--large {
    padding: toRem(20) toRem(40);
    font-size: toRem(18);
    line-height: 1.35;
  }

  &--medium {
    padding: toRem(16) toRem(32);
    font-size: toRem(16);
    line-height: 1.4;
  }

  &--small {
    padding: toRem(12) toRem(24);
    font-size: toRem(14);
    line-height: 1.4;
  }

  //   Give full purple gradient background
  &--primary {
    background-image: $gradient-purple;
    color: $color-white;
    transition: 0.2s;

    &:hover {
      background-image: none;
      background-color: $color-purple;
      color: $color-white;
    }

    &:focus {
      color: $color-white;
    }

    &:first-child {
      &:hover {
        background-image: none;
        background-color: $color-purple;
        color: $color-white;
      }

      &:not(.btn-check) {
        & + .btn {
          &:hover {
            background-image: none;
            background-color: $color-purple;
            color: $color-white;
          }
        }
      }
    }

    &:not(.btn-check) {
      & + .btn {
        &:hover {
          background-image: none;
          background-color: $color-purple;
          color: $color-white;
        }
      }
    }
  }

  //   Give purple gradient border
  &--secondary-purple {
    position: relative;

    & > p {
      color: transparent;
      background: $gradient-purple;
      -webkit-background-clip: text;
      background-clip: text;
    }

    .btn__icon {
      background-image: url('../../media/images/icons/arrow-right-purple.svg');
    }

    &::before {
      content: '';
      position: absolute;
      inset: 0;
      border-radius: 10px;
      border: 1px solid transparent;
      background: $gradient-purple border-box;
      -webkit-mask: linear-gradient($color-white 0 0) padding-box, linear-gradient($color-white 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
    }

    &:hover {
      border: none;
      background: none;
      background: $color-purple;

      p {
        color: $color-white;
      }

      &::before {
        inset: unset;
      }

      .btn__icon {
        background-image: url('../../media/images/icons/arrow-right.svg');
      }
    }

    &:first-child {
      &:hover {
        border: none;
        background: none;
        background: $color-purple;

        p {
          color: $color-white;
        }

        &::before {
          inset: unset;
        }

        .btn__icon {
          background-image: url('../../media/images/icons/arrow-right.svg');
        }
      }

      &:not(.btn-check) {
        & + .btn {
          &:hover {
            border: none;
            background: none;
            background: $color-purple;

            p {
              color: $color-white;
            }

            &::before {
              inset: unset;
            }

            .btn__icon {
              background-image: url('../../media/images/icons/arrow-right.svg');
            }
          }
        }
      }
    }

    &:not(.btn-check) {
      & + .btn {
        &:hover {
          border: none;
          background: none;
          background: $color-purple;

          p {
            color: $color-white;
          }

          &::before {
            inset: unset;
          }

          .btn__icon {
            background-image: url('../../media/images/icons/arrow-right.svg');
          }
        }
      }
    }
  }

  //   Button with white outline
  &--secondary-white {
    color: $color-white;
    border: 1px solid $color-white;

    &:hover {
      border: 1px solid transparent;
      background-image: none;
      background-color: $color-purple;
      color: $color-white;
    }

    &:focus {
      color: $color-white;
      border: 1px solid $color-purple;
    }

    &:first-child {
      &:hover {
        border: 1px solid transparent;
        background-image: none;
        background-color: $color-purple;
        color: $color-white;
      }

      &:not(.btn-check) {
        & + .btn {
          &:hover {
            border: 1px solid transparent;
            background-image: none;
            background-color: $color-purple;
            color: $color-white;
          }
        }
      }
    }

    &:not(.btn-check) {
      & + .btn {
        &:hover {
          border: 1px solid transparent;
          background-image: none;
          background-color: $color-purple;
          color: $color-white;
        }
      }
    }
  }

  //   Button with no border, no background color, white text
  &--transparent-white {
    color: $color-white;
    background-color: transparent;
    font-family: 'DMSans Regular';

    &:hover {
      color: $color-light-purple;
      font-family: 'DMSans Regular';
    }

    &:not(.btn-check) {
      &:hover {
        color: $color-light-purple;
        font-family: 'DMSans Regular';
      }
    }
  }
}

// Button used for Login with Icon
.btn-login {
  padding: toRem(18) 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: toRem(8);
  background-color: $color-white;
  border: solid 1px $color-light-grey;
  text-transform: none;

  &__icon {
    width: 24px;
    aspect-ratio: 1 / 1;
  }

  // Use class text-m-bold for text
  &__text {
    margin-bottom: 0;
    color: $color-black;
  }

  &:hover {
    border: solid 1px $color-grey;
  }

  &:first-child {
    &:hover {
      border: solid 1px $color-grey;
    }

    &:not(.btn-check) {
      & + .btn {
        &:hover {
          border: solid 1px $color-grey;
        }
      }
    }
  }
}

//   Footer Social Media Icon
.btn-box {
  padding: toRem(8);
  background-color: rgba($color-white, 0.2);

  &__box-icon {
    width: 32px;
    aspect-ratio: 1 / 1;
  }

  &:hover {
    background-color: $color-light-purple;
  }

  &:first-child {
    &:hover {
      background-color: $color-light-purple;
    }

    &:not(.btn-check) {
      & + .btn {
        &:hover {
          background-color: $color-light-purple;
        }
      }
    }
  }

  &:not(.btn-check) {
    & + .btn {
      &:hover {
        background-color: $color-light-purple;
      }
    }
  }
}

.btn-back {
  border-radius: 56px;
  padding: 0.5em;
  background-color: $color-white;
  outline: solid 1px $color-lighter-grey;

  &__icon {
    width: 24px;
    aspect-ratio: 1 / 1;
  }

  &:hover {
    background-color: $color-white;
  }

  &:first-child {
    &:hover {
      background-color: $color-white;
    }

    &:not(.btn-check) {
      & + .btn {
        &:hover {
          background-color: $color-white;
        }
      }
    }
  }
}
