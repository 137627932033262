.select2-container {
}

.select2-selection {
  position: relative;
  height: unset !important;
  padding: toRem(16) !important;
  background-color: $color-white !important;
  border: 1px solid $color-light-grey !important;
  border-radius: 10px !important;
  outline: none !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: 0.5s ease-out;

  &:hover,
  &:focus {
    border: 1px solid $color-purple !important;
  }

  &__placeholder {
    font-weight: $font-weight-normal !important;
    color: $color-light-grey !important;
    padding: 0 !important;
  }

  &__rendered {
    font-weight: $font-weight-normal !important;
    color: $color-black !important;
    padding: 0 !important;
  }

  &__arrow {
    content: '';
    position: absolute;
    right: 0;
    width: 24px;
    height: 24px;
    background-image: url('../../media/images/icons/expand-down.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    transition: $accordion-icon-transition;
    top: 50% !important;
    right: 16px !important;
    transform: translateY(-50%);

    b {
      display: none;
    }
  }
}

.select2-dropdown {
  margin-top: toRem(16) !important;
  border: none !important;
  border-radius: 10 !important;
  box-shadow: $shadow-sm !important;
  overflow: hidden;
}

.select2-results {
  &__options {
    max-height: 260px !important;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__option {
    font-weight: $font-weight-normal !important;
    color: $color-black;
    padding: toRem(14) toRem(20) !important;

    &--selectable {
      background-color: $color-white !important;
      color: $color-black !important;
    }

    &--highlighted {
      background-color: $color-lighter-purple !important;
      color: $color-black !important;
    }
  }
}
