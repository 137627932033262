@font-face {
  font-family: 'DMSans Regular';
  src: url(../../media/fonts/DMSans-Regular.ttf);
}

@font-face {
  font-family: 'DMSans Medium';
  src: url(../../media/fonts/DMSans-Medium.ttf);
}

@font-face {
  font-family: 'DMSans Bold';
  src: url(../../media/fonts/DMSans-Bold.ttf);
}