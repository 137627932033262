// Forms
.form-search {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $color-white;
  border-radius: 10px;
  padding: 8px 8px 8px 28px;
  grid-column-gap: 8px;
  color: $color-light-grey;
  border: solid 1px $color-light-grey;

  &::placeholder {
    color: $color-light-grey;
  }

  @include media-breakpoint-down(sm) {
    padding: 0;
    flex-direction: column;
    background: transparent;
    grid-row-gap: 12px;
    border: unset;
  }

  &__input {
    position: relative;
    display: flex;
    align-items: center;
    flex: 1;
    grid-column-gap: 8px;

    @include media-breakpoint-down(sm) {
      width: 100%;
      border: solid 1px $color-light-grey;
      border-radius: 10px;
    }

    &--img {
      height: 24px;
      width: 24px;

      @include media-breakpoint-down(sm) {
        position: absolute;
        left: 16px;
      }
    }

    &--inp {
      outline: none;
      border: none;
      flex: 1;
      background: transparent;

      @include media-breakpoint-down(sm) {
        height: auto;
        width: 100%;
        background: $color-white;
        padding: 16px 16px 16px 45px;
        border-radius: 10px;
      }

      &::placeholder {
        color: $color-light-grey;
      }
    }
  }

  &__btn {
    position: relative;

    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }

  // Width
  &--large {
    width: 541px;

    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }

  &--medium {
    width: 412px;

    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }
}

// Input
.items {
  position: relative;
  flex: 1;

  &__label {
    margin-bottom: 8px;
    color: $color-black;
    font-weight: 700;
    text-transform: capitalize;
  }

  //if there is no textarea
  &__inputs {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 4px;
    padding: 6px 16px;
    grid-column-gap: 8px;
    border: 1px solid $color-light-grey;
    border-radius: 10px;
    transition: 0.5s ease-out;

    &:hover {
      border: 1px solid $color-purple;
    }

    &:hover .items__inputs__img {
      background-image: url('../../media/images/icons/at-purple.svg');
    }

    &:hover .items__inputs__img--card {
      background-image: url('../../media/images/icons/credit-card-purple.svg');
    }

    &::placeholder {
      color: $color-light-grey;
    }

    &__img {
      width: 23px;
      height: 20px;
      background-image: url('../../media/images/icons/at.svg');
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      transition: 0.5s ease-out;

      &--card {
        background-image: url('../../media/images/icons/credit-card.svg');
      }
    }

    &--input {
      height: 44px;
      background: transparent;
      outline: none;
      border: none;
      flex: 1;
      color: $color-black;

      &:active .items__inputs__img {
        background-color: $color-purple;
      }
    }
  }

  // if there is a textarea
  &__inputs-area {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
    margin-bottom: 4px;
    padding: 6px 16px;
    grid-column-gap: 8px;
    border: 1px solid $color-light-grey;
    border-radius: 10px;
    transition: 0.5s ease-out;

    &:hover {
      border: 1px solid $color-purple;
    }

    &--textarea {
      width: 100%;
      padding: 6px 0px;
      border: none;
      outline: none;
      background: transparent;
      color: $color-black;
      resize: none;

      &:focus {
        color: $color-black;
      }
      &:focus + .items__inputs-area--span {
        color: $color-purple;
      }
    }

    &--span {
      color: $color-light-grey;
    }
  }

  // if there is no textarea error
  &__inputs-error {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 4px;
    padding: 6px 16px;
    grid-column-gap: 8px;
    border: 1px solid $color-red;
    border-radius: 10px;
    transition: 0.5s ease-out;

    &__img {
      width: 20px;
      height: 20px;
      background-image: url('../../media/images/icons/at.svg');
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      transition: 0.5s ease-out;
    }

    &--input {
      height: 44px;
      background: transparent;
      outline: none;
      border: none;
      flex: 1;
      color: $color-red;

      &:focus {
        color: $color-red;
      }
    }
  }

  // if there is a textarea error
  &__inputs-area-error {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
    margin-bottom: 4px;
    padding: 6px 16px;
    grid-column-gap: 8px;
    border: 1px solid $color-red;
    border-radius: 10px;
    transition: 0.5s ease-out;

    &--textarea {
      width: 100%;
      padding: 6px 0px;
      border: none;
      outline: none;
      background: transparent;
      color: $color-red;
      resize: none;

      &:focus {
        color: $color-black;
      }
    }

    &--span {
      color: $color-red;
    }
  }

  // Link No Error
  &__link {
    text-decoration: none;
    color: $color-light-grey;

    &:hover {
      color: $color-light-grey;
    }
  }

  // Link Error
  &__link-error {
    text-decoration: none;
    color: $color-red;

    &:hover {
      color: $color-red;
    }
  }

  // Checkbox
  &__checkbox {
    display: block;
    position: relative;
    padding-left: 35px;
    text-transform: capitalize;
    cursor: pointer;
    user-select: none;

    &:hover input ~ .items__checkbox--checkmark {
      border: 1px solid $color-purple;
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;

      &:checked ~ .items__checkbox--checkmark {
        background: $color-purple;
        border: 1px solid $color-purple;
      }
      &:checked ~ .items__checkbox--checkmark::after {
        display: block;
      }
    }

    &--checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 20px;
      width: 20px;
      border: 1px solid $color-light-grey;
      border-radius: 2px;

      &::after {
        content: '';
        position: absolute;
        display: none;
        left: 5.5px;
        top: 1px;
        width: 7px;
        height: 11.5px;
        border: solid $color-white;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }
    }
  }
}

// Dropdown
.drop {
  position: relative;
  width: 275px;

  // Select
  &__dropdown {
    position: relative;
    display: flex;
    align-items: center;
    gap: 6px;
    border-radius: 10px;
    background: $color-white;
    padding: 6px 16px;
    height: 56px;
    border: 1px solid $color-light-grey;
    transition: 0.5s ease-out;

    &:hover {
      border: 1px solid $color-purple;
    }

    &:hover p {
      color: $color-black;
    }

    p {
      position: relative;
      text-transform: capitalize;
      color: $color-light-grey;
      flex: 1;
      transition: 0.5s ease-out;
    }
  }

  &__ul {
    position: absolute;
    padding: 6px 16px;
    background: $color-white;
    flex: 1;
    width: 100%;
    list-style: none;
    border-radius: 10px;
    margin-top: 5px;
    display: none;

    li {
      margin-top: 16px;
      display: block;

      &:last-child {
        margin-bottom: 16px;
      }
    }
  }
}
