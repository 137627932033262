.card {
  overflow: hidden;
  border: none;
  border-radius: 10px;

  &--social,
  &--social-2 {
    position: relative;
    z-index: 1;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $color-white;
    height: max-content;
    padding: toRem(24);
  }

  &--article-type-1 {
    box-shadow: $shadow-md;

    .row.g-0 {
      @include media-breakpoint-down(lg) {
        flex-direction: column-reverse;
      }
    }
  }

  &--article-type-2 {
    box-shadow: $shadow-sm;
    z-index: 1;
  }

  &--article-type-3 {
    box-shadow: $shadow-md;
  }

  &--article-type-4 {
    box-shadow: $shadow-md;
  }

  &--article-type-5 {
    box-shadow: $shadow-sm;
  }

  &--article-type-6 {
    background-color: transparent;
  }

  &--article-type-7 {
    position: relative;
    box-shadow: $shadow-sm;
  }
}

//Set Card Image Photo
.card-img-top{
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

// Set Card that Have an Ornaments
.card-pattern {
  position: absolute;
  pointer-events: none;

  &--social,
  &--social-2 {
    z-index: -1;
    width: 110%;
    top: toRem(-27);
    left: 0;
  }

  &--type-2 {
    right: toRem(-27);
    top: 1px;
    z-index: -1;
  }

  &--type-7 {
    z-index: 1;
    top: 40%;
    left: 20%;
    transform: translate(50%, 50%);
  }
}

.card-ornament {
  pointer-events: none;

  &--social,
  &--social-2 {
    width: 110%;
    aspect-ratio: 14 / 9;
  }

  &--type-2 {
    width: 207px;
    aspect-ratio: 2.69 / 1;
  }

  &--type-7 {
    width: 207px;
    aspect-ratio: 2.2 / 1;
  }
}

// Set Body Container of Card
.card-body {
  padding: 0;
  z-index: 2;

  &--social,
  &--social-2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &--type-1 {
    width: 50.4%;
    padding: toRem(44);
    padding-top: toRem(46);

    @include media-breakpoint-down(lg) {
      width: 100%;
      padding: toRem(36) toRem(40);
    }

    @include media-breakpoint-down(sm) {
      padding: toRem(24) toRem(20);
    }
  }

  &--type-2 {
    width: 63%;
    padding: toRem(24);
  }

  &--type-3 {
    width: 100%;
    padding: toRem(36) toRem(40);

    @include media-breakpoint-down(sm) {
      padding: toRem(24) toRem(20);
    }
  }

  &--type-4 {
    width: 100%;
    padding: toRem(36);
  }

  &--type-5 {
    width: 100%;
    padding: toRem(24);
  }

  &--type-6 {
    width: 100%;
  }

  &--type-7 {
    width: 100%;
    padding: toRem(24);
    padding-top: toRem(8);
  }
}

// Set Photo on Article Card
.card-photo {
  &--type-1 {
    width: 49.6%;
    aspect-ratio: 1.54 / 1;
  }

  &--type-2 {
    width: 37%;
    aspect-ratio: 0.98 / 1;
    z-index: 3;
  }

  &--type-1,
  &--type-2 {
    @include media-breakpoint-down(lg) {
      overflow: hidden;
      width: 100%;
      aspect-ratio: 1.68 / 1;
    }

    & > img {
      width: 100%;
    }

    .img-desktop {
      @include media-breakpoint-down(lg) {
        display: none;
      }
    }

    .img-mobile {
      @include media-breakpoint-up(lg) {
        display: none;
      }
    }
  }

  &--type-3,
  &--type-4 {
    width: 100%;
    aspect-ratio: 1.68 / 1;
  }

  &--type-5,
  &--type-6,
  &--type-7 {
    width: 100%;
    aspect-ratio: 1.79 / 1;
  }

  &--type-7 {
    clip-path: polygon(0 0, 100% 0, 100% 61.7%, 0 100%);
    position: relative;
    z-index: 2;
  }
}

// Set Icon on Social Card
.card-icon {
  width: 64px;
  aspect-ratio: 1 / 1;
}

// Set Section Inside Body
.card-header,
.card-profile,
.card-bottom {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

// Set Top Section of Article Card
.card-header {
  height: 28px;
  border: none;
  box-shadow: none;
  padding: 0;
  background-color: unset;
}

// Set Profile Section of Article Card
.card-profile {
  gap: toRem(12);
  color: $color-black;

  // for Profile Pic use img-fluid class
  &__img {
    width: 32px;
    aspect-ratio: 1 / 1;
  }
  // for Profile Name use text-m-bold
}

// Set Title of Card
.card-title {
  color: $color-dark-blue;
  margin: 0;

  // Use h4 for social card title
  &--social,
  &--social-2 {
    color: $color-dark-blue;
    margin-top: toRem(16);
    margin-bottom: toRem(8);
  }

  // All type card
  &--type-1,
  &--type-2,
  &--type-3,
  &--type-4,
  &--type-5,
  &--type-6,
  &--type-7 {
    &:hover {
      color: $color-purple;
      cursor: pointer;
    }
  }

  // Use h3 for type 1 title
  &--type-1 {
    margin-top: toRem(40);

    @include media-breakpoint-down(lg) {
      margin-top: toRem(24);
      margin-bottom: toRem(12);
    }
  }

  // Use h4 for type 2 title
  &--type-2 {
    margin-top: toRem(20);
    margin-bottom: toRem(8);
  }

  // Use h3 for type 3 title
  &--type-3 {
    margin-top: toRem(24);
    margin-bottom: toRem(12);
  }

  // Use h3 for type 4 title
  &--type-4 {
    margin-bottom: toRem(6);
  }

  // Use h4 for type 5 and 6 title
  &--type-6 {
    margin-top: toRem(13);
  }

  // Use h4 for type 7 title
  &--type-7 {
    margin-top: toRem(8);
    margin-bottom: toRem(8);
  }
}

// Set Paragraph for Card
.card-text {
  // Use text-m-regular for social card
  &--social,
  &--social-2 {
    margin-bottom: toRem(48);
  }

  // Use text-l-regular for type 1 card
  &--type-1 {
    margin-bottom: toRem(35);
    margin-top: toRem(10);
    @include text-shorten($line: 2);
  }

  // Use text-m-regular for type 2 card
  &--type-2 {
    @include text-shorten($line: 2);
  }

  // Use text-m-regular for type 3 and 4 card
  &--type-3,
  &--type-4 {
    margin-bottom: toRem(24);
    @include text-shorten($line: 2);
  }

  // Use text-m-regular for type 5 card
  &--type-5 {
    margin-top: toRem(12);
    @include text-shorten($line: 3);
  }

  // Use text-m-regular for type 6 card
  &--type-6 {
    margin-top: toRem(8);
    margin-bottom: toRem(20);
    @include text-shorten($line: 3);
  }

  &--type-7 {
    @include text-shorten($line: 3);
  }
}

// Use This Class for Day Status on Card
.card-day {
  color: $color-grey;
}
